<template>
    <el-row justify="center">
        <el-col :span="16" class="main-col">
            <div class="description">
                <div class="app-title">
                    <img src="/img/128x128.png" class="logo" />
                    Analyse Google Drive storage
                </div>
                <div class="content">
                    The extension is a tool for analyzing the storage space on a user's Google Drive account.
                    It uses the Pareto principle, which states that roughly 80% of effects come from 20% of causes,
                    to help users identify which files and folders are taking up the most space on their Drive.
                </div>
                <div class="content">
                    When a user opens the extension, they see a breakdown of their storage usage,
                    with folders at the top of the list, to make it easy for users to see
                    which files are taking up the most space.
                </div>

            </div>
            <el-collapse accordion v-model="activeName">
                <el-collapse-item name="about">
                    <template #title>
                        <div class="help-title">About extension</div>
                    </template>
                    <div>
                        <div class="content limited">
                            Showing data of Google Drive file/folder structure, this extensions using
                            <a href="https://en.wikipedia.org/wiki/Pareto_principle" target="_blank">Pareto
                                principle</a>
                            which define well-know 80/20 rule.
                        </div>
                        <div class="content limited">
                            Applying that idea to storage - we may say that 20% of folders in average take about 80% of
                            storage -
                            so consider top 20% of biggest folders it's possible very quickly clean up some space on
                            Google
                            Drive.
                            So using that idea, extension sort all folders by size and show them in 5 layers - each next
                            layer show folders
                            those take 80% of storage rest from previuos layer.
                        </div>
                        <div class="content limited">It could be shown in table:</div>
                        <el-table :data="tableData" style="width: 100%" border class="limited">
                            <el-table-column prop="l1" label="Level 1" />
                            <el-table-column prop="l2" label="Level 2" />
                            <el-table-column prop="l3" label="Level 3" />
                            <el-table-column prop="l4" label="Level 4" />
                            <el-table-column prop="l5" label="Level 5" />
                        </el-table>
                        <br />
                        <img class="content limited" src="/img/level-buttons.png" />
                    </div>
                </el-collapse-item>
                <el-collapse-item name="usage">
                    <template #title>
                        <div class="help-title">How to use</div>
                    </template>
                    <div class="content limited">
                        As scanning of Google Drive may take a lot of time - depends on how many folders and files are
                        in
                        the storage,
                        speed of internet connection etc - extension is using preloading of file/folder structure to
                        internal Chrome database
                        and then analysing of loading data.
                    </div>
                    <div class="content limited">
                        If you're running extenstion first time and there are no stored data on DB -
                        extension offer to run initial loading right after starting.
                        Later you may press button "Reload data from Google Drive" to run manual reloading of
                        file/folder
                        structure to DB.
                    </div>
                    <img src="/img/reload-button.png" />
                    <div class="content limited">
                        <strong>Please note:</strong> all changes in Google Drive you made on your Google Drive
                        are not showing in extension automatically - if you made significant changes, please reload data
                        manually.
                    </div>
                    <div class="content limited">
                        You may switch between layer using group buttons, each button shows number of files and total
                        storage
                        amount occupied by current layer.
                    </div>
                    <img src="/img/level-buttons.png" />
                    <div class="content limited">
                        To clean up folder (<em>deleting all folder or part of its content</em>) you should go to the
                        original Google Drive UI,
                        clicking on up arrow at the end of each row.
                    </div>
                </el-collapse-item>
                <el-collapse-item title="" name="contacts">
                    <template #title>
                        <div class="help-title">Contacts and issues</div>
                    </template>
                    <div class="content limited">
                        If you found some bugs or unappropriate behaviour of extension feel free to contact us by mail:
                        <a href="mailto:support@bogv.online">support@bogv.online</a>
                    </div>
                    <div class="content limited">
                        If you have some ideas on improvement of this extension, we will appreciate if you drop us
                        a short mail on address:
                        <a href="mailto:extensions@bogv.online">extensions@bogv.online</a>
                    </div>
                    <div class="content limited">
                        Huh. We're making extensions just to solve our own problems and help others to solve similar
                        problems.
                        And we will continue to do that anyway.
                        Though, thinking realistic, we're always happy to know that you like them and to get some
                        support to
                        do develop extensions more fastly, effciently and enthusiastic.
                        So if you would like to donate us kind of cup of coffee - we will really appreciate that. 8)
                    </div>
                    <el-button type="success">
                        <a href="https://www.paypal.com/donate/?hosted_button_id=ZHTQX5QCRGJM6" target="_blank">
                            Donate with PayPal
                        </a>
                    </el-button>
                </el-collapse-item>
                <el-collapse-item title="" name="privacy">
                    <template #title>
                        <div class="help-title">Privacy Policy</div>
                    </template>
                    <h2>Privacy Policy for "Google Drive Storage Analyzer" Chrome Extension</h2>

                    <div class="content limited">
                        This privacy policy applies to the "Google Drive Storage Analyzer" Chrome extension (the
                        "Extension") and explains how we collect, use, and share information about you when you use the
                        Extension.
                    </div>

                    <h3>Information We Collect</h3>
                    <div class="content limited">
                        The Extension collects information about the files and folders in your Google Drive account,
                        including the file names, types, sizes, and modification dates. The Extension does not collect the
                        content of your files or any other information about you or your account, such as your name or email
                        address.
                    </div>

                    <h3>How We Use Your Information</h3>
                    <div class="content limited">
                        We use the information collected by the Extension to provide you with a summary of your Google Drive
                        storage usage and to help you manage your storage more efficiently. We do not share this information
                        with third parties or use it for any other purpose.
                    </div>

                    <h3>Your Choices</h3>
                    <div class="content limited">
                        You can choose not to use the Extension or uninstall it at any time by visiting the Chrome
                        extensions page and clicking "Remove" or "Uninstall." You can also control the permissions that the
                        Extension has to your Google Drive account by visiting the Chrome extensions page and clicking
                        "Details."
                    </div>

                    <h3>Children's Privacy</h3>
                    <div class="content limited">
                        The Extension is not intended for children under the age of 13, and we do not knowingly collect
                        personal information from children under the age of 13.
                    </div>

                    <h3>Changes to This Privacy Policy</h3>
                    <div class="content limited">
                        We may update this privacy policy from time to time. We will post any changes on this page and
                        encourage you to review the privacy policy regularly.
                    </div>

                    <h3>Contact Us</h3>
                    <div class="content limited">
                        If you have any questions about this privacy policy or the Extension, please contact us at <a href="mailto:extensions@bogv.online">extensions@bogv.online</a>.
                    </div>
                </el-collapse-item>
            </el-collapse>
            <img src="/img/new_logo.png" class="logo" />
        </el-col>
    </el-row>
</template>

<script setup>
import { ref } from 'vue'
/* eslint-disable */

const tableData = [
    {
        l1: '80% of all storage',
        l2: '80% of 20%',
        l3: '80% of 4%',
        l4: '80% of 3.2%',
        l5: '80% of 0.8%',
    },
    {
        l1: '80% of all storage',
        l2: '16% of all storage',
        l3: '3.2% of all storage',
        l4: '0.8% of all storage',
        l5: '0.64% of all storage',
    }
]

</script>

<script>
export default {
    name: 'MainPage',
    props: {
    },
    mounted() {
        const hash = location.hash; 
        this.activeName = hash === '' ? 'about' : hash.slice(1);
    },
    data: () => {
        return {
            activeName: ref('about')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.help-page {
    padding: 15px;
}

.help-title {
    font-size: 1.3em;
    font-weight: 800;
    padding-left: 15px;
}

.app-title {
    font-size: xx-large;
    font-weight: 800;
    text-align: left;
    padding: 10px 15px;
}

.description {
    text-align: left;
    margin-bottom: 15px;
}

.description .logo {
    height: 80px;
    margin: 0;
    vertical-align: middle;
}
</style>

<style>
.el-collapse-item__wrap {
    text-align: left;
    padding: 0 0 0 15px;
}

.content {
    margin-bottom: 10px;
}

.description .content {
    line-height: 1.5em;
}

.limited {
    max-width: 720px;
}

.el-button--success a {
    color: #fefefe;
}

.logo {
    height: 50px;
    margin-top: 50px;
}

.el-row {
    /* max-width: 720px; */
}

.main-col {
    min-width: 780px;
    /* background-color: #fefefe; */
    /* padding: 0 0 0 15px; */
}
</style>
